<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Clientes / {{tituloAcao}}</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div v-if="tituloAcao" class="form-group">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-link active" id="nav-principal-tab" data-bs-toggle="tab" href="#nav-principal" role="tab" aria-controls="nav-principal" aria-selected="true">
              <font-awesome-icon :icon="['far', 'user']" fixed-width/>
              Dados cadastrais
            </a>
            <a class="nav-link" id="nav-enderecos-tab" data-bs-toggle="tab" href="#nav-enderecos" role="tab" aria-controls="nav-enderecos" aria-selected="false">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width/>
              Endereços
            </a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <!-- dados cadastrais -->
          <div class="tab-pane fade show active px-2" id="nav-principal" role="tabpanel" aria-labelledby="nav-principal-tab">
            <div class="row">
              <div class="col-6 col-md-3">
                <label>Tipo de cadastro</label>
                <select v-model.number="modalCliente.data.type" class="form-select">
                  <option value="1">Pessoa física</option>
                  <option value="2">Pessoa jurídica</option>
                </select>
              </div>
              <div v-if="modalCliente.data.type === 1" class="col-6 col-md-3">
                <label>CPF</label>
                <the-mask v-model="modalCliente.data.legalId" :mask="['###.###.###-##']" class="form-control" />
              </div>
              <div v-if="modalCliente.data.type === 2" class="col-6 col-md-3">
                <label>CNPJ</label>
                <the-mask v-model="modalCliente.data.legalId" :mask="['##.###.###/####-##']" class="form-control" />
              </div>
              <div v-if="modalCliente.data.type === 1" class="col-12 col-md-6">
                <label class="required">Nome completo</label>
                <input type="text" v-model="modalCliente.data.name" class="form-control">
              </div>
              <div v-if="modalCliente.data.type === 2" class="col-12 col-md-6">
                <label class="required">Razão social</label>
                <input type="text" v-model="modalCliente.data.name" class="form-control">
              </div>
              <div v-if="modalCliente.data.type === 2" class="col-6 col-md-3">
                <label>Inscrição Estadual</label>
                <input disabled type="text" v-model="modalCliente.data.rg_ie" class="form-control">
              </div>
              <div v-if="modalCliente.data.type === 2" class="col-6 col-md-3">
                <label>Inscrição Municipal</label>
                <input disabled type="text" v-model="modalCliente.data.inscricao_municipal" class="form-control">
              </div>
              <div v-if="modalCliente.data.type === 2" class="col-12 col-md-6">
                <label class="required">Nome fantasia</label>
                <input type="text" v-model="modalCliente.data.companyName" class="form-control">
              </div>
            </div>

            <!-- contatos -->
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3">
                <label>E-mail</label>
                <input type="email" v-model="modalCliente.data.email" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label>Telefone principal</label>
                <the-mask v-model="modalCliente.data.phones[0]" type="tel" :mask="['(##) ####-####', '(##) #####-####']" placeholder="(DDD) x0000-0000" class="form-control" />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label>Telefone 2</label>
                <the-mask v-model="modalCliente.data.phones[1]" type="tel" :mask="['(##) ####-####', '(##) #####-####']" placeholder="(DDD) x0000-0000" class="form-control" />
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label>Telefone 3</label>
                <the-mask v-model="modalCliente.data.phones[2]" type="tel" :mask="['(##) ####-####', '(##) #####-####']" placeholder="(DDD) x0000-0000" class="form-control" />
              </div>
              <div class="col-12">
                <label>Observação</label>
                <textarea v-model="modalCliente.data.obs" rows="4" class="form-control"></textarea>
              </div>
            </div>
            <!-- contatos -->

          </div>
          <!-- dados cadastrais -->

          <!-- endereços -->
          <div class="tab-pane fade pe-2" id="nav-enderecos" role="tabpanel" aria-labelledby="nav-enderecos-tab">
            <CustomerAddressEdit v-for="(address, i) in modalCliente.data.addresses" :key="address.id" :address="address" :index="i"  @remove-address="removeAddress(i)"/>
            <div class="flex ms-2 mt-2">
              <button type="button"
                  v-if="!modalCliente.editEndereco.id"
                  v-on:click="handleAddANewAddress()"
                  class="btn btn-outline-primary">
                <font-awesome-icon :icon="['fas', 'plus']"/>
                Adicionar mais um endereço
              </button>
            </div>
          </div>
          <hr class="mb-0">
          <!-- endereços -->

        </div>

        <div class="d-flex justify-content-end py-2">
          <button type="button" v-if="modalCliente.data.id" @click="handleRemoveCliente()" class="btn btn-outline-danger me-2">
            <font-awesome-icon :icon="['fas', 'trash']"/>
            Excluir
          </button>
          <button type="button"
              v-if="!modalCliente.data.id"
              v-on:click="handleModalClienteCadastrar()"
              v-bind:disabled="!podeSalvarCliente()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="!!modalCliente.data.id"
              v-on:click="handleModalClienteSalvar()"
              v-bind:disabled="!podeSalvarCliente()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import { vMaska } from "maska"
import CustomerAddressEdit from '../components/CustomerAddressEdit.vue'
import shared from '@/modules/shared'

export default {
  name: 'CustomersEdit',
  components: {
    CustomerAddressEdit
},
  directives: { maska: vMaska },
  data() {
    return {
      tituloAcao: '',
      customerID: undefined,
      listaClientes: [],
      modalCliente: {
        data: {
          id: undefined,
          type: 1,
          name: '',
          companyName: '',
          legalId: '',
          rg_ie: '',
          inscricao_municipal: '',
          email: '',
          phones: [],
          outros_contatos: '',
          addresses: [],
        },
        editEndereco: {
          id: undefined,
          zip: '',
          line1: '',
          complement: '',
          line2: '',
          city: '',
          state: undefined,
          latitude: undefined,
          longitude: undefined,
        }
      }
    }
  },
  methods: {
    initNewCustomer() {
      this.tituloAcao = 'Cadastrar';
      this.resetModalCliente();
      this.keepOneAddress();
    },

    initEditCustomer() {
      this.tituloAcao = 'Editar';

      api.get(`/customers/${this.customerID}`).then(res => {
        loading(false);
        if (!res.data.phones) {
          res.data.phones = [];
        }
        if (!res.data.type) {
          res.data.type = 1;
        }
        this.modalCliente.data = res.data;
        this.keepOneAddress();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleAdicionar() {
      this.abreModalCadastroCliente();
    },

    async handleEditaCliente(cliente) {
      this.resetModalCliente();

      // if (!cliente.enderecos) {
      //   await this.carregaEnderecosCliente(cliente).catch(() => {
      //     return;
      //   });
      // }

      this.modalCliente.data = clone(cliente);

      document.getElementById('btn-modal-edita-cliente-abre').click();
    },

    handleRemoveCliente() {
      dialogo.confirmacao(`Confirma remover o cliente <b>${this.modalCliente.data.name}</b>?`).then(() => {
        loading(true);

        api.delete(`/customers/${this.modalCliente.data.id}`).then(() => {
          loading(false);

          this.$router.push('/clientes');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    addressIsEmpty(address) {
      switch (true) {
        case (!!address.zip):
        case (!!address.line1):
        case (!!address.complement):
        case (!!address.line2):
        case (!!address.city):
        case (!!address.latitude):
        case (!!address.longitude):
          return false;
      }

      return true;
    },
    cleanEmptyAddresses() {
      for (let index = this.modalCliente.data.addresses.length-1; index >= 0; index--) {
        const element = this.modalCliente.data.addresses[index];
        if (this.addressIsEmpty(element)) {
          this.modalCliente.data.addresses.splice(index, 1);
        }
      }
    },
    keepOneAddress() {
      if (!this.modalCliente.data.addresses.length) {
        this.handleAddANewAddress();
      }
    },
    removeAddress(index) {
      const address = this.modalCliente.data.addresses[index];

      if (this.addressIsEmpty(address)) {
        this.modalCliente.data.addresses.splice(index, 1);
        // this.keepOneAddress();
        return;
      }

      dialogo.confirmacao(`Confirma remover o endereço <b>${shared.customer.addressReadable(address)}</b>?`).then(() => {
        this.modalCliente.data.addresses.splice(index, 1);
        // this.keepOneAddress();
      });
    },

    abreModalCadastroCliente() {
      this.resetModalCliente();
      document.getElementById('btn-modal-edita-cliente-abre').click();
    },

    resetModalCliente() {
      this.modalCliente.data.id = undefined;
      this.modalCliente.data.type = 1;
      this.modalCliente.data.name = '';
      this.modalCliente.data.companyName = '';
      this.modalCliente.data.legalId = '';
      this.modalCliente.data.rg_ie = '';
      this.modalCliente.data.inscricao_municipal = '';
      this.modalCliente.data.email = '';
      this.modalCliente.data.phones = [];
      this.modalCliente.data.outros_contatos = '';

      this.modalCliente.data.addresses = [];

      this.resetModalEditEndereco();
    },

    resetModalEditEndereco() {
      this.modalCliente.editEndereco.id = undefined;
      this.modalCliente.editEndereco.zip = '';
      this.modalCliente.editEndereco.line1 = '';
      this.modalCliente.editEndereco.complement = '';
      this.modalCliente.editEndereco.line2 = '';
      this.modalCliente.editEndereco.city = '';
      this.modalCliente.editEndereco.state = undefined;
      this.modalCliente.editEndereco.latitude = undefined;
      this.modalCliente.editEndereco.longitude = undefined;
    },

    buscarGeocodePeloEndereco() {
      let addr = shared.customer.addressReadable(this.modalCliente.editEndereco).replace(" ", "+");

      loading(true);
      api.get(`/customers/geocode?address=${addr}`).then(res => {
        loading(false);
        this.modalCliente.editEndereco.latitude = res.data.lat;
        this.modalCliente.editEndereco.longitude = res.data.lng;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    podeSalvarCliente() {
      switch (true) {
        case (this.modalCliente.data.name.length < 3):
        case (this.modalCliente.data.type === 2 && this.modalCliente.data.companyName.length < 3):
          return false;

        default:
          return true;
      }
    },

    handleAddANewAddress() {
      this.modalCliente.data.addresses.push({
        id: undefined,
        zip: '',
        line1: '',
        complement: '',
        line2: '',
        city: '',
        state: undefined,
        latitude: undefined,
        longitude: undefined,
      });
      this.resetModalEditEndereco();
    },

    handleModalClienteCadastrar() {
      this.cleanEmptyAddresses();
      const sendData = {...this.modalCliente.data};
      sendData.phones = sendData.phones.filter(x => x);

      loading(true);
      api.post('/customers', sendData).then(() => {
        loading(false);
        this.$router.push('/clientes');
      }).catch(error => {
        this.keepOneAddress();
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalClienteSalvar() {
      this.cleanEmptyAddresses();
      const sendData = {...this.modalCliente.data};
      sendData.phones = sendData.phones.filter(x => x);

      loading(true);
      api.put(`/customers`, sendData).then(() => {
        loading(false);
        this.$router.push('/clientes');
      }).catch(error => {
        this.keepOneAddress();
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    switch (this.$route.name) {
      case 'CustomersNew':
        this.initNewCustomer();
        break;

      default: {
        this.customerID = this.$route.params.customerID;
        if (!this.customerID) {
          this.$router.push({ path: `/clientes` });
          return;
        }

        this.initEditCustomer();
      }
    }
  }
}
</script>
