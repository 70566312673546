<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import { vMaska } from "maska"
import shared from '@/modules/shared'

export default {
  name: "CustomerAddressEdit",
  directives: { maska: vMaska },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    address: {
      type: Object,
      default() {
        return {
          id: undefined,
          zip: '',
          line1: '',
          number: '',
          complement: '',
          line2: '',
          city: '',
          state: undefined,
          latitude: undefined,
          longitude: undefined,
        }
      },
    },
  },
  data() {
    return {
      analizeResult: {
          ispPlans: 0,
          ispSubscriptions: 0,
        },
      form: {
        file: undefined,
        ispPlans: false,
        ispSubscriptions: false,
      },
    }
  },
  methods: {
    buscarGeocodePeloEndereco() {
      let addr = shared.customer.addressReadable(this.address).replace(" ", "+");

      loading(true);
      api.get(`/customers/geocode?address=${addr}`).then(res => {
        loading(false);
        this.address.latitude = res.data.lat;
        this.address.longitude = res.data.lng;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    podeSalvarEndereco() {
      switch (true) {
        case (this.address.line1.length < 3):
        case (this.address.line2.length < 3):
        case (this.address.city.length < 3):
        case (!this.address.state):
          return false;

        default:
          return true;
      }
    },

    handleBtnRemoveAddress() {
      this.$emit('remove-address');
    },
  },
};
</script>

<style scoped>
.card-endereco {
  border-left: 4px solid;
  border-radius: 4px;
  border-top: 1px solid rgba(9, 113, 250, 0.15) !important;
  border-bottom: 1px solid rgba(9, 113, 250, 0.15) !important;
  border-right: 1px solid rgba(9, 113, 250, 0.15) !important;
}
.card-endereco:hover {
  border-top: 1px solid rgba(9, 113, 250, 0.15) !important;
  border-bottom: 1px solid rgba(9, 113, 250, 0.15) !important;
  border-right: 1px solid rgba(9, 113, 250, 0.15) !important;
  box-shadow: 0 0.1em 1em 0.1em rgba(0, 0, 0, 0.15);
}
</style>

<template>
  <div class="flex p-2 ms-2 mt-2 mb-3 card-endereco border-primary">
    <div class="row pb-2">
      <div class="col-12">
        <button type="button" @click="handleBtnRemoveAddress()" class="btn btn-outline-danger float-end">
          <font-awesome-icon :icon="['fas', 'times']"/>
          Remover este endereço
        </button>
        <h4 class="text-muted-2">#{{ index + 1 }}</h4>
      </div>
      <div class="col-12 col-md-5">
        <label>Descrição / título (opcional)</label>
        <input type="text" v-model="address.description" class="form-control" placeholder="Endereço de casa / loja">
      </div>
      <div class="col-12 col-sm-3 col-md-2">
        <label>CEP</label>
        <input v-maska data-maska="#####-###" type="text" v-model="address.zip" class="form-control">
      </div>
      <div class="col-12 col-sm-6 col-md-5">
        <label class="required">Logradouro (Rua/Avenida/etc)</label>
        <input type="text" v-model="address.line1" class="form-control" placeholder="Nome da rua ou avenida">
      </div>
      <div class="col-12 col-sm-3 col-md-2">
        <label>Número</label>
        <input type="text" v-model="address.number" class="form-control" placeholder="">
      </div>
      <div class="col-6 col-sm-6 col-md-3">
        <label>Complemento</label>
        <input type="text" v-model="address.complement" class="form-control" placeholder="Apto, andar, piso, etc">
      </div>
      <div class="col-6 col-md-3">
        <label class="required">Bairro</label>
        <input type="text" v-model="address.line2" class="form-control">
      </div>
      <div class="col-6 col-sm-6 col-md-4">
        <label class="required">Cidade</label>
        <input type="text" v-model="address.city" class="form-control">
      </div>
      <div class="col-6 col-sm-6 col-md-6">
        <label class="required">Estado</label>
        <select v-model="address.state" class="form-select">
          <option disabled :value="undefined">Selecione</option>
          <option value="MG">Minas Gerais</option>
          <option disabled value="DESATIVADO">Outros (solicite ativação)</option>
        </select>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <label>Coordenadas GPS</label>
        <div class="dropdown input-group">
          <input type="number" min="-90" max="90" v-model="address.latitude" placeholder="Latitude" class="form-control hide-spin">
          <input type="number" min="-180" max="180" v-model="address.longitude" placeholder="Longitude" class="form-control hide-spin">
          <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="triggerDDAcoesOnu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
          </button>
          <ul class="dropdown-menu dropdown-menu-primary" aria-labelledby="triggerDDAcoesOnu">
            <li>
              <button v-on:click="buscarGeocodePeloEndereco()" type="button" class="dropdown-item">
                <font-awesome-icon :icon="['fas', 'globe-americas']" fixed-width/> Buscar pelo endereço
              </button>
            </li>
            <li>
              <button disabled type="button" class="dropdown-item">
                <font-awesome-icon :icon="['fas', 'map']" fixed-width/> Procurar no mapa
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
